'use client';

import React, { memo, useState } from 'react';
import Head from 'next/head';
import Breadcrumb from '@components/breadcrumb';
import FindDealer from '@components/findDealer';
import Layout from '@components/layout';
import { BlockRenderer } from '@components/renderer';
import ArticleSchema from '@components/schemaorg';
import Section from '@components/section';
import { onEnterSpacePress } from '@utils/keyboard';

/**
 * @class
 * @param {Object} pageData Page Data for Landing Page elements
 *
 * @returns Landing Component as per the page data
 */

function Landing({ pageData, headerData, footerData, locales, slug }) {
  const [overlay, setOverlayStatus] = useState(false);
  const isHeroWithHeading =
    pageData?.sections?.[0]?.sys?.contentType?.sys?.id === 'heroSectionV2';
  const [selectedItem, setSelectedItem] = useState(null);
  const seoMetadata = pageData?.seoMetadata?.fields;

  const externalContentMap = {
    'find-a-dealer': (
      <>
        <Head>
          <script id='schemaorg_dealersearch' type='application/ld+json'>
            {`{"@context": "https://schema.org",
              "@type": "SearchAction",
              "name": "${seoMetadata?.pageTitle}",
              "description": "${seoMetadata?.description}",
              "url": "${seoMetadata?.canonicalUrl}",
              "query": "Dealers"}`}
          </script>
        </Head>
        <FindDealer />
      </>
    )
  };

  const handleSearchOpen = () => {
    setOverlayStatus(!overlay);
  };
  console.log(slug, '>>>>>>', pageData);
  return (
    <Layout
      headerData={headerData}
      footerData={footerData}
      locales={locales}
      isHeroWithHeading={isHeroWithHeading}
      testId='layout'
      slug={slug}
      overlay={overlay}
      handleSearchOpen={handleSearchOpen}
    >
      {overlay ? (
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-70 backdrop-brightness-75 z-10 transition-opacity'
          onClick={handleSearchOpen}
          onKeyDown={onEnterSpacePress(handleSearchOpen)}
        />
      ) : null}
      <>
        <Breadcrumb
          backgroundColor={
            pageData?.sections?.[0].fields?.backgroundColor ||
            pageData?.sections?.[0].fields?.sectionBackgroundColor
          }
        />
        {pageData.externalContent ? (
          externalContentMap[slug]
        ) : (
          <>
            <ArticleSchema seoMetadata={seoMetadata} />
            {pageData?.sections?.map((section, index) => (
              // position represents position of section here.
              <Section
                key={`${section?.sys?.id}-${index}`}
                id={section?.sys?.id}
                selectedItem={selectedItem}
                onClick={item => setSelectedItem(item)}
                sectionData={section?.fields}
                sectionType={section?.sys?.contentType?.sys?.id}
                position={index}
                sectionBackgroundColor={section?.fields?.sectionBackgroundColor}
              />
            ))}
            <BlockRenderer block={pageData?.sections} />
          </>
        )}
      </>
    </Layout>
  );
}

export default memo(Landing);
