import React from 'react';
// import { ComponentContentTypes } from '@/lib/constants';
import { parseExperiences } from '@lib/pageParsers';
import { Experience } from '@ninetailed/experience.js-next';
import get from 'lodash/get';
import HeroBanner from '@components/heroBanner';
import HeroSectionV2 from '@components/heroV2';

// const ContentTypeMap = {
//   [ComponentContentTypes.Banner]: Banner,
//   [ComponentContentTypes.CTA]: CTA,
//   [ComponentContentTypes.Hero]: Hero,
//   [ComponentContentTypes.HubspotForm]: HubspotForm,
//   [ComponentContentTypes.Feature]: Feature,
//   [ComponentContentTypes.Footer]: Footer,
//   [ComponentContentTypes.Navigation]: Navigation,
//   [ComponentContentTypes.PricingPlan]: PricingPlan,
//   [ComponentContentTypes.PricingTable]: PricingTable,
//   [ComponentContentTypes.ProductDetail]: ProductDetail,
//   [ComponentContentTypes.ProductPolicy]: ProductPolicy,
//   [ComponentContentTypes.SectionsGroup]: SectionsGroup
// };

const ComponentRenderer = props => {
  const contentTypeId = props.sys.contentType.sys.id;

  // const Component = ContentTypeMap[contentTypeId];

  // if (!Component) {
  //   console.warn(`${contentTypeId} can not be handled`);
  //   return null;
  // }
  const heroFields = props?.fields;
  return <HeroBanner sectionData={heroFields} />;
};

export const BlockRenderer = ({ block }) => {
  if (Array.isArray(block)) {
    return (
      <div>
        {block.map(b => {
          if (!b) {
            return null;
          }
          return <BlockRenderer key={`block-${b.sys.id}`} block={b} />;
        })}
      </div>
    );
  }

  if (!block) {
    return null;
  }

  const contentTypeId = get(block, 'sys.contentType.sys.id');
  const { id } = block.sys;
  const parsedExperiences = parseExperiences(block);
  return (
    <Experience
      key={`${contentTypeId}-${id}`}
      {...block}
      id={id}
      component={ComponentRenderer}
      experiences={parsedExperiences}
    />
  );
};
